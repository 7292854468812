import { Box, Button, Container, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'
import NextLink from 'next/link'

import { PageNotFoundIllustration } from '../src/assets'
import { MotionContainer, varBounceIn } from '../src/components/animate'
import Page from '../src/components/Page'
import LogoOnlyLayout from '../src/layouts/LogoOnlyLayout'

// components

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingBottom: theme.spacing(10),
}))
const CommingSoonImage = styled('img')({
  margin: 'auto',
  height: 'auto',
})
// ----------------------------------------------------------------------

export default function PageNotFound() {
  return (
    <LogoOnlyLayout>
      <RootStyle title="404 Page Not Found | Orava">
        <Container>
          <MotionContainer initial="initial" open>
            <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
              <motion.div variants={varBounceIn}>
                {/* <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} /> */}
                <CommingSoonImage
                  src="/static/Orava_embarassed.png"
                  alt="comming soon"
                  height="400"
                  width="400"
                />
              </motion.div>
              <motion.div variants={varBounceIn}>
                <Typography variant="h3" paragraph>
                  Sorry, page not found!
                </Typography>
              </motion.div>
              <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the
                URL? Be sure to check your spelling.
              </Typography>

              <NextLink href="/">
                <Button size="large" variant="contained" sx={{ borderRadius: '25px' }}>
                  Go to Home
                </Button>
              </NextLink>
            </Box>
          </MotionContainer>
        </Container>
      </RootStyle>
    </LogoOnlyLayout>
  )
}
